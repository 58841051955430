import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/research/medicine/001.jpg';
// import blogd2 from '../../assets/images/2022/federal-seminar/2.jpg';
// import blogd3 from '../../assets/images/2022/federal-seminar/3.jpg';
// import blogd4 from '../../assets/images/2022/federal-seminar/4.jpg';
// import blogd5 from '../../assets/images/2022/federal-seminar/5.jpg';
// import blogd6 from '../../assets/images/2022/federal-seminar/6.jpg';
// import blogd7 from '../../assets/images/2022/federal-seminar/7.jpg';
// import blogd8 from '../../assets/images/2022/federal-seminar/8.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Overview of Diabetes Mellitus (DM)
                </h2>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Definition/Introduction
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Is a metabolic condition occurring as a result of persistent,
                  prolonged elevated blood glucose (hyperglycaemia) caused lack
                  of the insulin production, insufficient insulin production or
                  inability of the body to make use of the insulin produced. Due
                  to problem to the hormone insulin, there are disorders of
                  carbohydrate, protein, fats & oil, vitamins, minerals and
                  water. This condition is associated with significant reduction
                  in Life expectancy, complications (both small and large
                  vessels) and / or death. It is estimated that about
                  537millions people have DM as at 2021 and it is projected to
                  increase to about 783millions in 2045. In Africa, there is
                  about 24 million people with DM as at 2021 and it is projected
                  that by 2045, there will be 55million which is 134% increase.
                  Currently the prevalence of DM in Nigeria as been on the
                  gradual rise, with the figure about 2.24% in 1994 and the
                  pooled prevalence figure being 5.77% from a systematic review
                  (North-west zone having the lowest prevalence of 3.8% and
                  South-south zone, 9,8%. Risk factors for DM include genetic,
                  infection (for Type 1 DM), overweight/obesity, lack of
                  physical activity, poor diet, age -45 years, Positive family
                  history of DM, history of gestational DM or birth weight
                  -3.9kg, hypertension, abnormal lipids levels, prediabetes
                  state, polycystic ovarian syndrome chronic use of steroids.
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Types of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  There are four types of DM. Type 1, Type 2, Hyperglycaemia
                  first detected in Pregnancy and other specific types. Type 2
                  DM is the commonest, consisting about 90% of DM.
                </p>
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Common classical symptoms of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Increased thirst, increased urination, excessive hunger &
                  eating, nocturia and weight loss. These symptoms develop
                  rapidly (T1DM) or gradually (T2DM). T2DM may not present with
                  any symptoms but with complications such as high glucose
                  crisis or emergencies, stroke, blindness, kidney failure,
                  heart attack, heart failure, poor erection or impotence,
                  infertility, foot ulcer and peripheral artery diseases.
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Complications of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  If left untreated, may present with acute complications such
                  as hyperglycaemic emergencies such as Diabetes Ketoacidosis,
                  Hyperosmolar Hyperglycaemic state, lactic acidosis and
                  Hypoglycaemia. Long term complications include microvascular
                  (damage to small blood vessels) such as the eyes (cataract,
                  glaucoma, blindness), kidney (chronic kidney disease), nerves
                  (DM neuropathy presenting with numbess, tingling, pain,
                  altered sensation) and coronary artery disease (heart attack).
                  Macrovascular include stroke, peripheral vascular disease,
                  cardiovascular disease Diabetic foot disease, dementia
                </p>
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Diagnosis of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Fasting plasma glucose level ≥ 126mg/dL (7.0mmol/l) on two
                  consecutive readings Plasma glucose ≥ 200mg/dl (11.1mmol/l)
                  two hours after 75g oral glucose load as in a glucose
                  tolerance test Symptoms of high blood glucose and casual
                  plasma glucose ≥ 200mg/dl (11mmol/l) Glycated haemoglobin
                  (HbA1c) ≥ 6.5% (48mmol/mol)
                </p>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Management of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Healthy diets, exercise, weight loss, use of appropriate
                  medications (drugs either oral or injectables) and surgery
                  atimes.
                </p>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Prevention
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Maintain optimal body weight, physical exercise, (30 to 45mins
                  of moderate exercise per day) healthy diet. Limit sugary and
                  alcoholic beverages, Smoking cessation
                </p>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Department of Medicine</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
